.bed-details-child {
  position: absolute;
  top: 0;
  left: -324px;
  width: 1092px;
  height: 780px;
  display: none;
  mix-blend-mode: screen;
}

.bedexpand {
  margin-bottom: 15px;
  width: calc(100% - 1px);
  top: 131px;
  right: 925px;
  left: 298px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  font-size: 14px;
  color: #000;
  flex-direction: column;
}

.Bedinfo_Fields {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  gap: 20px;
}

.bed-details-child {
  top: 73px;
  left: -195px;
  background: radial-gradient(50% 50%at 50% 50%,
      rgba(19, 60, 147, 0.53),
      rgba(19, 60, 147, 0.58));
  width: 768px;
  mix-blend-mode: normal;
}


.bed-details-child1 {
  position: absolute;
  width: calc(100% - 472px);
  top: 89px;
  right: 208px;
  left: 264px;
  background-color: var(--color-white);
  height: 504px;
}

.bedline-div {
  position: absolute;
  width: calc(100% - 480px);
  top: 158.5px;
  right: 210.5px;
  left: 269.5px;
  border-top: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 1px;
}

.bedexpand-more-24px-icon {
  position: relative;
  width: 23px;
  height: 24px;
  display: none;
}

.beddetails {
  position: relative;
  font-weight: 600;
}

.bedexpand-more-24px-parent {
  position: absolute;
  width: calc(100% - 1px);
  top: 131px;
  right: 925px;
  left: 298px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--font-size-base);
  color: var(--color-black);
}

.destination-name-input {
  border: 0;
  background-color: transparent;
  flex: 1;
}

.bedorgForm-fields,
.bedorgForm-fields1,
.bedorgForm-fields2,
.bedorgForm-fields3,
.bedorgForm-fields4,
.bedorgForm-fields5,
.bedorgForm-fields6,
.bedorgForm-fields7 {
  position: absolute;
  width: calc(100% - -2182px);
  right: 245px;
  left: 90px;
  top: 82px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.bedorgForm-fields {
  top: 277px;
  gap: var(--gap-3xs);
}

.bedorgForm-fields1,
.bedorgForm-fields2,
.bedorgForm-fields3,
.bedorgForm-fields4,
.bedorgForm-fields5,
.bedorgForm-fields6,
.bedorgForm-fields7 {
  top: 357px;
}

.bedorgForm-fields2,
.bedorgForm-fields3,
.bedorgForm-fields4,
.bedorgForm-fields5,
.bedorgForm-fields6,
.bedorgForm-fields7 {
  top: 361px;
  gap: var(--gap-3xs);
}

.bedorgForm-fields3,
.bedorgForm-fields4,
.bedorgForm-fields5,
.bedorgForm-fields6,
.bedorgForm-fields7 {
  top: 447px;
}

.bedorgForm-fields4,
.bedorgForm-fields5,
.bedorgForm-fields6,
.bedorgForm-fields7 {
  top: 597px;
}

.bedorgForm-fields5,
.bedorgForm-fields6,
.bedorgForm-fields7 {
  top: 757px;
}

.bedorgForm-fields6,
.bedorgForm-fields7 {
  top: 677px;
}

.bedorgForm-fields7 {
  top: 837px;
}

.bedname-input13 {
  flex: 1;
}

.bedorgForm-fields8 {
  position: absolute;
  width: calc(100% - 545px);
  top: 197px;
  right: 245px;
  left: 300px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}

.bedframe-child1 {
  position: absolute;
  height: 72.73%;
  width: 100%;
  top: 27.27%;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-11xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.buttoncontainedtruefalse,
.label2 {
  position: absolute;
  top: 45px;
  left: 222px;
  opacity: 0.8;
}

.label2 {
  top: 0;
  left: 0;
  letter-spacing: -0.1px;
  opacity: 0.5;
  mix-blend-mode: normal;
}

.bedorgLabel2 {
  top: 0;
  left: 0;
  letter-spacing: -0.1px;
  opacity: 0.5;
  mix-blend-mode: normal;
}

.bedvector-parent {
  position: absolute;
  width: calc(100% - 540px);
  top: 1049px;
  right: 240px;
  left: 300px;
  height: 99px;
  color: var(--color-darkslateblue-300);
}

.bed-details {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  height: 785px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-tomato);
  font-family: var(--font-poppins);
}

.bedButtoncontainedtruefalse1 {
  position: absolute;
  height: 36.36%;
  width: 31.11%;
  top: 45.45%;
  right: 35.88%;
  bottom: 18.18%;
  left: 36.01%;
  opacity: 0.8;
}

.Address {
  position: absolute;
  width: calc(100% - 545px);
  right: 245px;
  left: 300px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  top: 678px;
  justify-content: flex-start;
}

.BedMaster-config-container {
  width: 60%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px;
  padding: 20px;
  height: 70vh;
  background-color: white;
}

.BedMaster-config-container-Main {
  display: flex;
  justify-content: center;
  height: 76vh;
  margin: 0px;
  background-color: #eaedf5;

}

.bedmaster-font {
  font-weight: 600;
}