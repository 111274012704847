.dashbordHover {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 10px;
    color: inherit;
    transition: background-color 0.3s ease;
}

.dashbordHover:hover {
    border-left: 2px solid #01cdff;
    background-color: #30465c;
}




